.container {
    margin: 20px;
}


h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.grid {
    margin-bottom: 20px;
}

.data-table {
    margin-top: 20px;
    overflow-x: auto;
}

.data-table .header {
    background-color: rgba(240, 242, 244, 1);
    margin-bottom: 0px;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid rgba(224, 224, 224, 1)     !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.data-table th,
.data-table td{
    border: 1px solid rgba(224, 224, 224, 1)     !important;
    padding: 8px;
    text-align: center;
}

.scrollable th, td {
    overflow-x: auto;
}

.data-table th.no-border-right, td.no-border-right {
    border-right: none !important;
}

.data-table th.scrollable-columns td.scrollable-columns {
    overflow-x: auto;
}

.data-table th.fixed-columns td.fixed-columns {
    position: sticky;
    z-index: 1;
}

.data-table th.no-border-left, td.no-border-left {
    border-left: none !important;
}

th {
    background-color: #f4f4f4;
}

.status {
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 4px;
    display: inline-block;
}

.status.approved-and-locked {
    background-color: rgba(41, 199, 111, 0.1);
    color: rgba(32, 159, 0, 1);
}

.status.pending-approval {
    background-color: rgba(90, 177, 249, 0.2);
    color: rgb(90, 177, 249);

}

.status.pending-submissions {
    background-color: rgba(249, 223, 127, 0.2);
    color: rgba(232, 137, 0, 1);
}

.status.unlocked-for-changes-and-comments {
    background-color: rgba(238, 87, 36, 0.1);
    color: rgba(238, 87, 36, 1);
}
