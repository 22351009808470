  
  /* Each "section" (checklist-group, header, etc.) */
  .report-section {
    margin-bottom: 1.5rem;
    border: 1px solid #e0e0e0;    /* a light gray border around the section */
    border-radius: 4px;
    overflow: hidden;             /* ensures the header background color spans full width */
  }

  
  /* The green bar at the top of each section */
  .section-header {
    background-color: #41AA95; 
    color: #fff;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #41AA95; /* slightly darker shade for separation */
  }
  
  .section-header h3 {
    margin: 0;
    font-size: 1.1rem;
    
  }
  .section-header h3 span{
   color: #fff !important;
   font-weight: bold;
  
  }
  .report-table tbody {
    /* Modern browsers support 8-digit HEX (#RRGGBBAA) */
    background-color: #EAEAEA80;
  
    /* For older browsers that don't support 8-digit HEX, 
       you can provide an rgba fallback as well: */
    background-color: rgba(234, 234, 234, 0.5);
  }
  /* Table styling */
  .report-table {
    width: 100%;
    border-collapse: collapse; /* merges adjacent cell borders */
    background-color: #fff;
  }
  
  /* Table headers */
  .report-table thead {
    background-color: #f5f5f5; /* light gray background for the header row */
  }
  
  .report-table th {
    font-weight: 600; /* or bold */
    text-align: left;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #ddd;
    white-space: nowrap; /* prevents awkward line breaks in header cells */
  }
  
  /* Table cells */
  .report-table td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #ddd;
    vertical-align: top; /* ensures text lines up at the top if there's varying row height */
  }
  