

.main-tabs,
.sub-tabs {
    display: flex;
    margin-bottom: 10px;
}

.tab,
.sub-tab {
    border: none;
    background-color: transparent;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #666;
    transition: color 0.3s ease;
}
.legend {
    display: flex;
    margin-bottom: 20px;
}

.goals-section {
    margin-bottom: 20px;
}

.targets-section {
    margin-top: 20px;
}

.pi-circle-on {
    font-size: 0.75rem;
}


.dropdowns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.dropdowns select {
    border: 1px solid #ccc;
    padding: 8px 12px;
    border-radius: 4px;
}

.tabs-container {
    font-family: 'Arial', sans-serif;
    /* Ensuring a clean, sans-serif font */
 
    /* White background */
    padding: 10px;
    /* Padding around the tabs for spacing */
   
    /* Subtle shadow for some depth */
}






.main-tabs {
    display: flex;
    gap: 10px;
}

.tab {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: #666;
    position: relative;
    /* Needed for absolute positioning of the pseudo-element */
    transition: background-color 0.3s, color 0.3s;
}

.tab.active {
    background-color: #41AA95;
    color: white;
    border-radius: 5px;

}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: -10px;
    /* Adjusts the position below the tab */
    left: 50%;
    /* Centers the arrow */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    /* Creates the left side of the arrow */
    border-right: 10px solid transparent;
    /* Creates the right side of the arrow */
    border-top: 10px solid #41AA95;
    /* Color and flip of the arrow */
    transform: translateX(-50%);
    /* Ensures the arrow is perfectly centered */
}

.sub-tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
    /* Adds a light grey bottom border for the whole sub-tab bar */
    margin-top: 10px;
    /* Spacing from the main tabs or other content */
}

.sub-tab {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #333;
    /* Darker text color for better contrast */
    font-size: 16px;
    transition: color 0.3s, border-color 0.3s;
    position: relative;
    /* Required for the pseudo-element */
}

.sub-tab:hover,
.sub-tab:focus {
    color: #666;
    /* Lighten the text color on hover for a subtle effect */
}

.sub-tab.active {
    font-weight: bold;
    /* Makes the active tab text bold */
}

.sub-tab.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    /* Aligns right at the bottom of the tab, over the bottom border */
    left: 0;
    right: 0;
    height: 2px;
    /* Height of the active underline */
    background-color: #315975;
    color: #315975;
    /* Active underline color */
    border-radius: 1px;
}