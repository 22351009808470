/* Text editor container */
.text-editor-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 0; /* Prevent collapsing */
    box-sizing: border-box;
}

/* Text view (non-editable) */
.text-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    position: relative;
}

/* Text content */
.text-content {
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Show ellipsis when text overflows */
    padding-right: 8px;
    font-size: 16px;
    flex-grow: 1; /* Ensure text takes up available space */
    flex-shrink: 0; /* Prevent shrinking */
    max-width:95%; /* Ensure text takes up to 80% of the width */
}

.text-content:hover {
    color: #315975;
}

/* Option icon (⋮) */
.option-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: linear-gradient(145deg, #f0f0f0, #cfcfcf);
    box-shadow: 3px 3px 5px #ccc, -3px -3px 5px #fff;
    transition: all 0.2s ease-in-out;
    flex-shrink: 0; /* Prevent shrinking */
}

.option-icon:hover,
.option-icon.active {
    background: linear-gradient(145deg, #d6d6d6, #e9e9e9);
    box-shadow: inset 2px 2px 4px #c2c2c2, inset -2px -2px 4px #f9f9f9;
    color: #007bff;
}

/* Overlay options */
.overlay-options {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 4px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    padding: 8px 0;
    border-radius: 4px;
}

.overlay-option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.overlay-option:hover {
    background-color: #f0f8ff;
    color: #315975;
}

.overlay-option.disabled {
    color: #ccc;
    pointer-events: none;
}

/* Edit mode */
.text-editor {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.text-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    box-sizing: border-box;
}

.text-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.text-input.error {
    border-color: #ff4d4f;
}

.text-input::placeholder {
    color: #ff4d4f;
}

/* Save and cancel icons */
.edit-actions {
    display: flex;
    gap: 8px;
}

.save-icon,
.cancel-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: linear-gradient(145deg, #f0f0f0, #cfcfcf);
    box-shadow: 3px 3px 5px #ccc, -3px -3px 5px #fff;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.2s ease-in-out;
}

.save-icon:hover {
    background: linear-gradient(145deg, #d6d6d6, #e9e9e9);
    color: green;
}

.cancel-icon:hover {
    background: linear-gradient(145deg, #d6d6d6, #e9e9e9);
    color: red;
}
